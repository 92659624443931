import { makeStyles } from 'tss-react/mui';

const useVendorStyles = makeStyles()((theme) => ({
  main: {
    maxWidth: 1200,
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      width: 'auto',
    },
  },
  mainContent: {
    display: 'flex',
    '&>article': {
      maxWidth: '45%',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      '&>article': {
        maxWidth: '100%',
      },
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      '&>article': {
        maxWidth: '100%',
      },
    },
  },
}));

export default useVendorStyles;
