import React from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import VendorForm from './VendorForm';
import useVendorStyles from './vendorStyles';
import PartnerList from '../../components/PartnerList';
import { Partner } from '../../components/PartnerList/PartnerList';

interface VendorsTemplateProps {
  title: string;
  mainTextContent: string[] | JSX.Element[];
  pageEnabled?: boolean;
  partnerList?: Partner[];
}

const VendorsTemplate = ({
  mainTextContent,
  title,
  pageEnabled,
  partnerList,
}: VendorsTemplateProps): JSX.Element => {
  const { classes } = useVendorStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <main className={classes.main}>
      {/* Add form to the bottom of the page */}
      {pageEnabled ? (
        <>
          <Box id="upload-target"></Box>
          <section>
            <Box>
              <Typography variant="h4">{title}</Typography>
              <Box className={classes.mainContent}>
                <article>
                  {mainTextContent.map((content, idx) => (
                    <p key={`mainContent_${idx}`}>{content}</p>
                  ))}
                </article>

                <VendorForm />
              </Box>
            </Box>
          </section>
        </>
      ) : (
        <Box>
          <Typography variant="h4" mb={2} align="center">
            {title}
          </Typography>
          <article>
            {mainTextContent.map((content, idx) => (
              <p key={`mainContent_${idx}`}>{content}</p>
            ))}
          </article>
          <PartnerList
            partners={partnerList}
            width={!isMobile ? 183 : '100%'}
          />
        </Box>
      )}
    </main>
  );
};

export default VendorsTemplate;
