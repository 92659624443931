import React from 'react';
import withHooks, { mapHooksToProps } from '../../common/utilities/withHooks';
import HomePageTemplate from './HomePage';

import Renaissance from './HomePageComponents/Renaissance';

export type DistributorLinks = {
  image: string;
  url: string;
};

interface HomePageData {
  title: string;
  distributors: {
    title: string;
    links: DistributorLinks[];
  };
  textContent: {
    main: (string | JSX.Element)[];
    title: string;
  };
}
const hooks = mapHooksToProps(() => {
  const homePageData: HomePageData = {
    title: 'Distributors of Artisan Foods',
    distributors: {
      links: [
        {
          image: '',
          url: '',
        },
      ],
      title: 'Distributors',
    },
    textContent: {
      main: [
        'Gourmet Merchants International (GMI) provides full-service distribution to gourmet, natural foods grocery markets from San Diego to Santa Barbara. We distribute and call on Bristol Farms, Erewhon, Whole Foods Markets, Lazy Acres and Gelson’s Markets as well as the independent gourmet stores in Southern California. Our sales representatives make regular calls on these stores to ensure continued distribution of our brands as well as bringing in new products.',
        'At GMI, we do not contract for guaranteed shelf placement. We go after our business by providing excellent service with an outstanding assortment of specialty products at an honest price and delivered on time.',
        <Renaissance />,
      ],
      title: 'Distributors of Artisan Foods',
    },
  };

  return homePageData;
});

const HomePage = withHooks(HomePageTemplate, hooks);

export default HomePage;
