import withHooks, { mapHooksToProps } from '../../common/utilities/withHooks';
import { Partner } from '../../components/PartnerList/PartnerList';
import useMainContextProvider from '../../Providers/MainContextProvider/useMainContextProvider';
import VendorsTemplate from './Vendors';
import celticSeaSalt from '../../assets/partners/celtic_sea_salt.png';
import fransChocolate from '../../assets/partners/frans_chocolate.png';
import giusti from '../../assets/partners/giust.png';
import godiva from '../../assets/partners/godiva.jpg';
import johnKelly from '../../assets/partners/john_kelly.png';
import justJans from '../../assets/partners/just_jans.jpg';
import laudemio from '../../assets/partners/laudemio.png';
import maldon from '../../assets/partners/maldon.png';
import malibuHoney from '../../assets/partners/malibu_honey.png';
import marichChocolates from '../../assets/partners/marich_chocolates.png';
import masa from '../../assets/partners/masa.jpg';
import mizuba from '../../assets/partners/mizuba_tea.png';
import nielsonMassey from '../../assets/partners/nielson_massey.jpg';
import olioVerde from '../../assets/partners/olio_verde.jpg';
import organicPantry from '../../assets/partners/organic_pantry.png';
import philzCoffee from '../../assets/partners/philz_coffee.jpg';
import picsPeanutButter from '../../assets/partners/pics_peanut_butter.jpg';
import sacredSnacks from '../../assets/partners/sacred_snacks.jpg';
import terrapin from '../../assets/partners/terrapin_ridge.png';
import tondo from '../../assets/partners/tondo.png';
import parnterRustichella from '../../assets/partners/partner_rustichella.jpg';
import parnterLaTourangelle from '../../assets/partners/partner_la_tourangelle.png';
import simpsons from '../../assets/partners/simpsonbrands_large.jpg';
import bigIslandBees from '../../assets/partners/big_island_bees.png';


interface VendorsProps {
  title: string;
  mainTextContent: string[] | JSX.Element[];
  pageEnabled?: boolean;
  partnerList?: Partner[];
}
const hooks = mapHooksToProps(() => {
  const { mainContextState } = useMainContextProvider();

  const { available } = mainContextState.mainContextPageState.vendorsWelcome;

  const pageData: VendorsProps = {
    mainTextContent: [
      'Our true value is that we have a long–standing relationship with the buyers at the five major gourmet chains – Gelsons, Bristol Farms, Erewhon, Lazy Acres and Whole Foods Market as well as many independent gourmet markets throughout Southern California. We are a regional distributor that offers our artisan manufacturers and vendors a direct path to retailers through our intimate knowledge of the local market and our personal involvement with our buyers and chain category managers.',
      'That will give you a quick to market response!  In addition, our seasoned sales team, will keep a personal eye on your stock and set and will be an excellent tool to get you into the strong independent stores throughout the Southern California Region.',
    ],
    title: 'Vendors Welcome',
    pageEnabled: available,
    partnerList: [
      {
        image: celticSeaSalt,
        url: 'https://celticseasalt.com/',
        alt: 'Celtic Sea Salt',
      },
      {
        image: fransChocolate,
        url: 'https://frans.com/',
        alt: 'Frans Chocolate',
      },
      {
        image: giusti,
        url: 'https://giusti.com/',
        alt: 'Giusti Modena',
      },
      {
        image: godiva,
        url: 'https://www.godiva.com/',
        alt: 'Godiva',
      },
      {
        image: johnKelly,
        url: 'https://www.johnkellychocolates.com/',
        alt: 'John Kelly Chocolates',
      },
      {
        image: justJans,
        url: 'https://www.justjans.com',
        alt: 'Just Jans',
      },
      {
        image: laudemio,
        url: 'https://www.laudemiofrescobaldi.com/en/',
        alt: 'Laudemio Frescobaldi',
      },
      {
        image: maldon,
        url: 'https://maldonsalt.com/us/',
        alt: 'Maldon Sea Salt',
      },
      {
        image: malibuHoney,
        url: 'https://www.malibuhoney.com/',
        alt: 'Malibu Honey',
      },
      {
        image: marichChocolates,
        url: 'https://marich.com/',
        alt: 'Marich Chocolates',
      },
      {
        image: masa,
        url: '  https://www.masa.com/',
        alt: 'M AS A',
      },
      {
        image: mizuba,
        url: 'https://mizubatea.com/',
        alt: 'Mizuba Tea',
      },
      {
        image: nielsonMassey,
        url: 'https://nielsenmassey.com/',
        alt: 'Nielson Massey',
      },
      {
        image: olioVerde,
        url: 'https://oliooliveoil.com/',
        alt: 'Olio Verde',
      },
      {
        image: organicPantry,
        url: 'https://www.theorganicpantryco.com/',
        alt: 'Organic Pantry Co',
      },
      {
        image: philzCoffee,
        url: 'https://philzcoffee.com/',
        alt: 'Philz Coffee',
      },
      {
        image: picsPeanutButter,
        url: 'https://picspeanutbutter.com/',
        alt: 'Pics Really Good Peanut Butter',
      },
      {
        image: sacredSnacks,
        url: 'https://eatsacredsnacks.com/',
        alt: 'Sacred Snacks',
      },
      {
        image: terrapin,
        url: 'https://terrapin.com/',
        alt: 'Terapin',
      },
      {
        image: tondo,
        url: 'https://www.tondochocolate.com/',
        alt: 'Tondo nautura a tutto tondo',
      },
      {
        image: parnterRustichella,
        url: 'https://www.rustichella.it/',
        alt: 'Rustichella',
      },
      {
        image: parnterLaTourangelle,
        url: 'https://latourangelle.com/',
        alt: 'Latourangelle',
      },
      {
        image: simpsons,
        url: 'https://www.simpsonbrands.com',
        alt: 'Simpson Brands',
      },
      {
        image: bigIslandBees,
        url: 'https://www.simpsonbrands.com',
        alt: 'Simpson Brands',
      },
    ],
  };
  return {
    ...pageData,
  };
});

const Vendors = withHooks(VendorsTemplate, hooks);

export default Vendors;
