import React from 'react';

import ourCustomersStyles from './ourCustomersStyles';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import PartnerList from '../../components/PartnerList';
import { OurCustomersTemplateData } from '.';
import Tout from '../../components/Tout';

const OurCustomersTemplate: React.FC<OurCustomersTemplateData> = ({
  distributors,
  textContent,
  title,
  partners,
}) => {
  const { classes } = ourCustomersStyles();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <main>
      <section className={classes.mainWrapper}>
        {/* <Box className={classes.sideNav}>
          <nav>
            <PartnerList />
          </nav>
        </Box> */}
        <Box className={classes.mainContentWrapper}>
          <Typography variant="h4">{textContent.title}</Typography>
          <Box className={classes.mainContent}>
            <article>
              <Box mb={4}>
                <Tout
                  bodyText={textContent.main[0]}
                  bodyTextVariant="body1"
                  bodyTextColor="MenuText"
                  imageUrl={textContent.images?.[0]}
                  flexDirection={'row-reverse'}
                  alignToutContent="normal"
                />
              </Box>

              <PartnerList
                partners={partners}
                width={!isMobile ? 183 : '100%'}
              />
            </article>
            {/* <img className={classes.mainImage} src={iceCream} alt="ice-cream" /> */}
            {/* <a href="https://www.vecteezy.com/free-photos/dessert">
              Dessert Stock photos by Vecteezy
            </a> */}
          </Box>
        </Box>
      </section>
    </main>
  );
};

export default OurCustomersTemplate;
