import React from 'react';

import { DistributorLinks } from '.';
import { Box, Typography } from '@mui/material';
import useHomePageStyles from './styles';
import gourmetCounter from '../../assets/gourmet_counter.jpg';
import PartnerList from '../../components/PartnerList';

interface HomePageTemplateData {
  title: string;
  distributors: {
    title: string;
    links: DistributorLinks[];
  };
  textContent: {
    main: (string | JSX.Element)[];
    title: string;
  };
}

const HomePage = ({
  distributors,
  title,
  textContent,
}: HomePageTemplateData) => {
  const { classes } = useHomePageStyles();
  return (
    <main>
      <section className={classes.mainWrapper}>
        <Box className={classes.sideNav}>
          <nav>
            <PartnerList />
          </nav>
        </Box>
        <Box className={classes.mainContentWrapper}>
          <Typography variant="h4">{textContent.title}</Typography>
          <Box className={classes.mainContent}>
            <Box display="flex" flexDirection="column">
              {textContent.main.map((text, idx) => (
                <article key={`text_${idx}`}>{text}</article>
              ))}
            </Box>
            <img
              className={classes.mainImage}
              src={gourmetCounter}
              alt="ice-cream"
            />
            {/* <a href="https://www.vecteezy.com/free-photos/dessert">
              Dessert Stock photos by Vecteezy
            </a> */}
            {/* <a href="https://www.freepik.com/free-ai-image/abundance-fresh-organic-fruits-wicker-basket-generated-by-ai_42648151.htm#fromView=search&page=1&position=3&uuid=ecbb8370-8b20-4e04-a36f-3ea55649fb8e:~:text=Image%20by%20vecstock%20on%20Freepik">
              Dessert Stock photos by Image by vecstock on Freepik
            </a> */}
          </Box>
        </Box>
      </section>
    </main>
  );
};
export default HomePage;
