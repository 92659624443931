import { makeStyles } from 'tss-react/mui';

const usePartnerListStyles = makeStyles()((theme) => ({
  main: {
    width: 'auto',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: 12,
    [theme.breakpoints.down('lg')]: {
      justifyContent: 'flex-start',
    },
    [theme.breakpoints.down('sm')]: {
      gap: 24,
      flexDirection: 'column',
    },
  },
  partner: {
    width: '48%',
    // height: 'fit-content',
    boxShadow: '0px 3px 4px #333',
    borderRadius: 10,
    overflow: 'hidden',
    height: 154,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    // padding: theme.spacing(2),
    '&>img': {
      display: 'block',
      width: '100%',
      // height: '90%',
      pointerEvents: 'none',
    },
    [theme.breakpoints.down('lg')]: {
      width: '19%',
    },
    [theme.breakpoints.down('md')]: {
      width: '22%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 'auto',
      // minHeight: 311,
      flexDirection: 'column',
    },
  },
}));

export default usePartnerListStyles;
